import Vue from 'vue';

import '@nedap/unrux/src/lib-scss/colors.scss';
import '@nedap/unrux/src/lib-scss/fonts/fonts.scss';

import { humanizedEnvironment } from '@nedap/unrux/src/lib-helpers/server_environments.js';

import { EnvironmentIndicator, Footer, SvgIcon } from '@nedap/unrux';

import 'components/session/session.scss';

window.vueApp = new Vue({
  el: '#app',
  filters: {
    humanizedEnvironment,
  },
  components: {
    EnvironmentIndicator,
    SvgIcon,
    'x-footer': Footer,
  }
});
